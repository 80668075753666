import React from "react"
import type { PageProps } from "gatsby"

import { NewHeader } from "../components/Headers/NewHeader"

import Layout from "../components/Layouts/secondaryPage"
import Error from "../components/Error"

import type { ErrorPageProps } from "../components/Error"

export default function ErrorPage({ location }: PageProps<object, object, ErrorPageProps>) {
  const props = location.state

  return (
    <Layout>
      <NewHeader redLogoWithSovcombank withButton={false} noRightSection noShadow />
      <Error {...props} />
    </Layout>
  )
}
